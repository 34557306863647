import Phaser from "phaser-ce";
import Upvote from "./assets/upvote.png";
import You from "./assets/you.png";
import ArrowPhysics from "./assets/arrow_physics.json";

let game: Phaser.Game;
let totalObjects: number = 0;
let totalTime: number = 0;
let width: number = 0;
let height: number = 0;
let _bodies: any[] = [];

let fallingKey:string = "upvote";
let fallingUrl: string = Upvote;

let dumpeeKey:string = "dumpee";
let dumpeeUrl: string = You;

let preset:string = "upvote";

let mouseBody: any;
let mouseConstraint: any;

function preload() {
  game.load.image(fallingKey, fallingUrl);
  game.load.image(dumpeeKey, dumpeeUrl);
  game.load.physics('arrowPhysics', undefined, ArrowPhysics);
}

function create() {
  game.physics.startSystem(Phaser.Physics.P2JS);
  game.physics.p2.gravity.y = 1000;

  let dumpee = game.add.sprite(game.width / 2, 0, dumpeeKey);
  dumpee.anchor.set(0.5);
  dumpee.scale.set((game.width * 0.5) / dumpee.width);
  dumpee.position.set(game.world.centerX, game.height - dumpee.height - 5);
  game.physics.p2.enable(dumpee, false);
  _bodies.push(dumpee.body);

  mouseBody = new p2.Body();
  game.physics.p2.world.addBody(mouseBody);

  // attach pointer events
  game.input.onDown.add(click);
  game.input.onUp.add(release);
  game.input.addMoveCallback(move, null);
}

function update() {
  if (totalObjects < 50 && game.time.now > totalTime) {
    createObj();
  }
}

function createObj() {
  let obj = game.add.sprite(Math.random() * game.width, 10, fallingKey);
  obj.scale.set((game.width / 10) / obj.width);

  game.physics.p2.enable(obj, false);
  totalObjects++;
  totalTime = game.time.now + 50;
  let body = obj.body as Phaser.Physics.P2.Body;
  body.setCircle(obj.width / 2);

  _bodies.push(body);

  // if (preset === "upvote") {
  //   body.clearShapes();
  //   body.loadPolygon('arrowPhysics', 'upvote', obj.scale.x);
  // }
}

export function setFallingUrl(url: string) {
  fallingUrl = url;
  fallingKey = Math.random() + "";

  preset = "none";
  totalObjects = 0;
  totalTime = 0;
  _bodies = [];

  if (game) {
    game.state.restart(true, false);
  }
}

export function setDumpeeUrl(url: string) {
  dumpeeUrl = url;
  dumpeeKey = Math.random() + "";

  totalObjects = 0;
  totalTime = 0;
  _bodies = [];

  if (game) {
    game.state.restart(true, false);
  }
}

export function setFallingPreset(newPreset: string) {
  preset = newPreset
}

function click(pointer: Phaser.Pointer) {
  var bodies = game.physics.p2.hitTest(pointer.position, _bodies);
  
  // p2 uses different coordinate system, so convert the pointer position to p2's coordinate system
  var physicsPos = [game.physics.p2.pxmi(pointer.position.x), game.physics.p2.pxmi(pointer.position.y)];
  
  if (bodies.length)
  {
      var clickedBody = bodies[0];
      
      var localPointInBody = [0, 0];
      // this function takes physicsPos and coverts it to the body's local coordinate system
      clickedBody.toLocalFrame(localPointInBody, physicsPos);
      
      // use a revoluteContraint to attach mouseBody to the clicked body
      mouseConstraint = game.physics.p2.createRevoluteConstraint(mouseBody, [0, 0], clickedBody, [game.physics.p2.mpxi(localPointInBody[0]), game.physics.p2.mpxi(localPointInBody[1]) ]);
  }   
}

function release() {
  // remove constraint from object's body
  game.physics.p2.removeConstraint(mouseConstraint);
}

function move(pointer: Phaser.Pointer) {
  // p2 uses different coordinate system, so convert the pointer position to p2's coordinate system
  mouseBody.position[0] = game.physics.p2.pxmi(pointer.position.x);
  mouseBody.position[1] = game.physics.p2.pxmi(pointer.position.y);
}

export function startGame(gameWidth: number, gameHeight: number) {
  if (gameWidth === 0 || gameHeight === 0) {
    return;
  }

  game = new Phaser.Game(gameWidth, gameHeight, Phaser.CANVAS, "game", {
    preload,
    create,
    update
  });
}