import React, { Component } from 'react';
import "../../util/setupPhaser";
import {startGame, setFallingUrl, restartGame, setDumpeeUrl} from "./DumperGame";
import ReactResizeDetector from 'react-resize-detector';
import "../../css/BasicToy.css";

class DumperToy extends Component {
  componentDidMount() {
    if (this.props.fallingUrl && this.props.bottomUrl) {
      console.log("This flow");
      setFallingUrl(this.props.fallingUrl);
      setDumpeeUrl(this.props.bottomUrl);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.fallingUrl != prevProps.fallingUrl) {
      console.log("Setting fallurl", this.props.fallingUrl);
      setFallingUrl(this.props.fallingUrl);
    }

    if (this.props.bottomUrl != prevProps.bottomUrl) {
      setDumpeeUrl(this.props.bottomUrl);
    }
  }

  onResize = (width, height) => {
    startGame(width, height);
  }

  render() {
    return (
      <div className="BasicToy">
        <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} />
        <div className="PhaserGame" id="game"/>
      </div>
    );
  }
}

export default DumperToy;