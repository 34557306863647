import React, { Component } from "react";
import "../css/Toy.css";
import toyFirebase from "../util/toyFirebase";
import TOOL_DATA from "../util/toolData";

export default class Toy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toyData: {},
      version: Date.now() + ""
    };
    this.iframeRef = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.toyId != this.props.toyId) {
      this.startListening(this.props.toyId);
    }
  }

  componentDidMount() {
    if (this.props.toyId) {
      this.startListening(this.props.toyId);
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  startListening(toyId) {
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    this.unsubscribe = toyFirebase.firestore().collection("toys").doc(toyId).onSnapshot(doc => {
      if (!doc.exists) {
        return;
      }
      let toyData = doc.data();
      toyData.docId = doc.id;

      this.onToyData(toyData);
      this.setState({
        toyData
      });
    });
  }

  onToyData(toyData) {
    if (this.props.onToyData) {
      this.props.onToyData(toyData);
    }
  }

  getContainerStyle() {
    if (this.props.size) {
      return {width: this.props.size, paddingTop: this.props.size};
    }

    return {};
  }

  getToyData() {
    if (this.props.toyData) {
      return this.props.toyData;
    }

    return this.state.toyData;
  }

  // TODO use switch statement
  getToy() {
    let type = this.getToyData().type;

    if (!type) {
      type = this.props.type;
    }

    if (!type) {
      return null;
    }
    return TOOL_DATA[type].toyComponent;
  }

  render() {
    let ComponentToy = this.getToy();

    return (
      <div className="ToyContainer" style={this.getContainerStyle()}>
        <div className="Toy">
          {ComponentToy && <ComponentToy key={this.state.version} {...this.getToyData()} isPreview={this.props.preview} />}
        </div>
      </div>
    );
  }
}
