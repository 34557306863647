const tileData = [
  {
    img: '/static/images/grid-list/breakfast.jpg',
    title: 'Scratch',
    subtitle: 'scratch cards plz',
    cols: 2,
    featured: true,
    type: "scratch"
  },
  {
    img: '/static/images/grid-list/burgers.jpg',
    title: 'Blinker',
    subtitle: 'tap to blink 👀',
    type: "blinker"
  },
  {
    img: "",
    title: "Dumper",
    subtitle: "dump stuff",
    type: "dumper"
  },
  {
    img: "",
    title: "Flappy",
    subtitle: "Avoid something",
    type: "flappy"
  }
];

// TODO merge with other data set
export default tileData;