import React, { Component } from 'react';
import Iframe from "react-iframe";
var _ = require('lodash/core');

class iFrameToy extends Component {
  constructor(props) {
    super(props);
    
    this.iframeRef = null;
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props, prevProps)) {
      this.sendToyData();
    }
  }
  
  sendToyData = () => {
    this.iframeRef.refs.iframe.contentWindow.postMessage(
      this.props,
      "*"
    );
  };

  iframeLoaded = () => {
    this.sendToyData();
  };

  render() {
    return (
      <Iframe
        url={this.props.url}
        ref={ref => (this.iframeRef = ref)}
        display="initial"
        position="relative"
        styles={{ overflow: "hidden" }}
        onLoad={this.iframeLoaded}
      />
    );
  }
}

export default iFrameToy;