import React, { Component } from "react";
import "../css/ScratchToy.css";
import ReactResizeDetector from 'react-resize-detector';
import TopImg from "../images/toys/scratch/top.png";
import BottomImg from "../images/toys/scratch/bottom.png";

const PREVIEW_STROKES = [
  { mouseX: 111, mouseY: 154 },
  { mouseX: 111, mouseY: 159 },
  { mouseX: 111, mouseY: 163 },
  { mouseX: 111, mouseY: 167 },
  { mouseX: 111, mouseY: 172 },
  { mouseX: 111, mouseY: 175 },
  { mouseX: 111, mouseY: 177 },
  { mouseX: 111, mouseY: 179 },
  { mouseX: 111, mouseY: 179 },
  { mouseX: 112, mouseY: 180 },
  { mouseX: 112, mouseY: 181 },
  { mouseX: 113, mouseY: 182 },
  { mouseX: 114, mouseY: 182 },
  { mouseX: 114, mouseY: 182 },
  { mouseX: 115, mouseY: 182 },
  { mouseX: 115, mouseY: 182 },
  { mouseX: 116, mouseY: 182 },
  { mouseX: 117, mouseY: 180 },
  { mouseX: 121, mouseY: 176 },
  { mouseX: 125, mouseY: 169 },
  { mouseX: 131, mouseY: 161 },
  { mouseX: 136, mouseY: 156 },
  { mouseX: 140, mouseY: 150 },
  { mouseX: 145, mouseY: 146 },
  { mouseX: 147, mouseY: 144 },
  { mouseX: 149, mouseY: 143 },
  { mouseX: 149, mouseY: 143 },
  { mouseX: 150, mouseY: 143 },
  { mouseX: 151, mouseY: 143 },
  { mouseX: 152, mouseY: 142 },
  { mouseX: 153, mouseY: 142 },
  { mouseX: 153, mouseY: 142 },
  { mouseX: 153, mouseY: 142 },
  { mouseX: 153, mouseY: 143 },
  { mouseX: 156, mouseY: 148 },
  { mouseX: 158, mouseY: 153 },
  { mouseX: 162, mouseY: 158 },
  { mouseX: 167, mouseY: 164 },
  { mouseX: 172, mouseY: 170 },
  { mouseX: 176, mouseY: 176 },
  { mouseX: 180, mouseY: 179 },
  { mouseX: 183, mouseY: 182 },
  { mouseX: 185, mouseY: 185 },
  { mouseX: 187, mouseY: 186 },
  { mouseX: 188, mouseY: 187 },
  { mouseX: 189, mouseY: 188 },
  { mouseX: 189, mouseY: 188 },
  { mouseX: 192, mouseY: 190 }
];

class ScratchToy extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.canvasRef = React.createRef();
    this.imgRef = React.createRef();
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    let canvas = this.canvasRef.current;

    this.brushRadius = (canvas.width / 100) * 5;
    this.addListeners();
  }

  onMouseMove = e => {
    var brushPos = this.getBrushPos(e.clientX, e.clientY);
    var leftBut = this.detectLeftButton(e);
    if (leftBut == 1) {
      this.drawDot(brushPos.x, brushPos.y);
    }
  };

  onTouchMove = e => {
    e.preventDefault();
    var touch = e.targetTouches[0];
    if (touch) {
      var brushPos = this.getBrushPos(touch.pageX, touch.pageY);
      this.drawDot(brushPos.x, brushPos.y);
    }
  };

  addListeners() {
    let canvas = this.canvasRef.current;

    console.log("Adding listeners");
    canvas.addEventListener("mousemove", this.onMouseMove, false);
    canvas.addEventListener("touchmove", this.onTouchMove, false);
  }

  getContext() {
    return this.canvasRef.current.getContext("2d");
  }

  drawImageToCanvas = () => {
    let canvas = this.canvasRef.current;
    let ctx = this.getContext();
    let img = this.imgRef.current;

    // Reset the dimensions, for some reason this is needed :()
    canvas.width = this.containerRef.current.offsetWidth;
    canvas.height = this.containerRef.current.offsetHeight;
    ctx.width = this.containerRef.current.offsetWidth;
    ctx.height = this.containerRef.current.offsetHeight;

    this.drawImageProp(ctx, img, 0, 0, canvas.width, canvas.height);
    if (this.props.isPreview) {
      this.drawPreviewStrokes(0);
    }

  };

  drawPreviewStrokes(idx) {
    if (idx >= PREVIEW_STROKES.length) {
      return;
    }

    let stroke = PREVIEW_STROKES[idx];
    this.drawDot(stroke.mouseX, stroke.mouseY);
    setTimeout(() => this.drawPreviewStrokes(idx + 1), 10);
  }

  detectLeftButton(event) {
    if ("buttons" in event) {
      return event.buttons === 1;
    } else if ("which" in event) {
      return event.which === 1;
    } else {
      return event.button === 1;
    }
  }

  getBrushPos(xRef, yRef) {
    let canvas = this.canvasRef.current;
    var canvasRect = canvas.getBoundingClientRect();
    return {
      x: Math.floor(
        ((xRef - canvasRect.left) / (canvasRect.right - canvasRect.left)) *
          canvas.width
      ),
      y: Math.floor(
        ((yRef - canvasRect.top) / (canvasRect.bottom - canvasRect.top)) *
          canvas.height
      )
    };
  }

  drawDot(mouseX, mouseY) {
    let ctx = this.getContext();
    ctx.beginPath();
    ctx.arc(mouseX, mouseY, this.brushRadius, 0, 2 * Math.PI, true);
    ctx.fillStyle = "#000";
    ctx.globalCompositeOperation = "destination-out";
    ctx.fill();
  }

  drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {
    if (arguments.length === 2) {
      x = y = 0;
      w = ctx.canvas.width;
      h = ctx.canvas.height;
    }

    // default offset is center
    offsetX = typeof offsetX === "number" ? offsetX : 0.5;
    offsetY = typeof offsetY === "number" ? offsetY : 0.5;

    // keep bounds [0.0, 1.0]
    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;

    var iw = img.width,
      ih = img.height,
      r = Math.min(w / iw, h / ih),
      nw = iw * r, // new prop. width
      nh = ih * r, // new prop. height
      cx,
      cy,
      cw,
      ch,
      ar = 1;

    // decide which gap to fill
    if (nw < w) ar = w / nw;
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh; // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    // fill image in dest. rectangle
    ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
  }

  getBackgroundImage() {
    if (this.props.backPicUrl) {
      return `url("${this.props.backPicUrl}")`;
    } else if (this.props.isPreview) {
      return `url(${BottomImg})`
    }
  }

  refCallback = element => {
    if (element) {
      console.log(element.offsetHeight);
    }
  };

  onResize = () => {
    let canvas = this.canvasRef.current;
    let ctx = this.getContext();

    canvas.width = this.containerRef.current.offsetWidth;
    canvas.height = this.containerRef.current.offsetHeight;
    ctx.width = this.containerRef.current.offsetWidth;
    ctx.height = this.containerRef.current.offsetHeight;
    this.brushRadius = (canvas.width / 100) * 8;

    this.drawImageToCanvas();
  }

  getFrontImage = () => {
    if (this.props.frontPicUrl) {
      return this.props.frontPicUrl;
    } else if (this.props.isPreview) {
      return TopImg;
    }
  }

  render() {
    return (
      <div className="ScratchContainer" ref={this.containerRef}>
        <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} />
        <img
          className="ScratchImg"
          onLoad={this.drawImageToCanvas}
          ref={this.imgRef}
          src={this.getFrontImage()}
        />
        <canvas
          style={{ backgroundImage: this.getBackgroundImage() }}
          className="ScratchCanvas"
          ref={this.canvasRef}
        />
        <div className="ScratchText">{this.props.caption}</div>
      </div>
    );
  }
}

export default ScratchToy;
