import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';

var config = {
  apiKey: "AIzaSyBWBI2bjLzZDFOe2ox3BgokHHg2Pecv1EM",
  authDomain: "toybox-60cc1.firebaseapp.com",
  databaseURL: "https://toybox-60cc1.firebaseio.com",
  projectId: "toybox-60cc1",
  storageBucket: "toybox-60cc1.appspot.com",
  messagingSenderId: "594855221871"
};

var toyFirebase = firebase.initializeApp(config);
export default toyFirebase;