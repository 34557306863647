import ScratchToy from "../toys/ScratchToy";
import BlinkerToy from "../toys/BlinkerToy";
import DumperToy from "../toys/dumper/DumperToy";
import FlappyToy from "../toys/FlappyToy";

import EggplantIcon from "../images/toys/dumper/eggplant_icon.png";
import PeachIcon from "../images/toys/dumper/peach_icon.png";
import UpvoteIcon from "../images/toys/dumper/upvote_icon.png";

const TOOL_DATA = {
  scratch: {
    toyComponent: ScratchToy,
    description: "Scratch the image to reveal",
    directions: "drag on the image 👇",
    doneFinishText: "plz select two images 👆",
    fields: [
      {
        label: "frontPicUrl",
        buttonText: "Pick top image",
        type: "image",
        required: true
      },
      {
        label: "backPicUrl",
        buttonText: "Pick bottom image",
        type: "image",
        required: true
      },
      {
        label: "caption",
        buttonText: "Caption (optional)",
        type: "text",
        required: false
      }
    ]
  },
  blinker: {
    toyComponent: BlinkerToy,
    description: "Tap the image to switch it",
    directions: "tap to switch images 👇",
    doneFinishText: "plz select two images 👆",
    fields: [
      {
        label: "pic1Url",
        buttonText: "Pick first image",
        type: "image",
        required: true
      },
      {
        label: "pic2Url",
        buttonText: "Pick second image",
        type: "image",
        required: true
      },
      {
        label: "text1",
        buttonText: "First Caption (optional)",
        type: "text",
        required: false
      },
      {
        label: "text2",
        buttonText: "Second caption (optional)",
        type: "text",
        required: false
      }
    ]
  },
  dumper: {
    description: "Dump stuff on other stuff",
    toyComponent: DumperToy,
    directions: "try dragging stuff",
    doneFinishText: "plz select two images 👆",
    fields: [
      {
        label: "fallingUrl",
        buttonText: "Upload falling image",
        type: "image",
        required: true
      },
      {
        label: "fallingUrl",
        buttonText: "OR pick",
        type: "image_picker",
        required: true,
        images: [UpvoteIcon, EggplantIcon, PeachIcon]
      },
      {
        label: "bottomUrl",
        buttonText: "Upload bottom image",
        type: "image",
        required: true
      }
    ]
  },
  flappy: {
    description: "Flap around",
    toyComponent: FlappyToy,
    directions: "Tap to play",
    doneFinishText: "plz enter in both text 👆",
    fields: [
      {
        label: "charText",
        buttonText: "Character text",
        type: "text",
        required: true
      },
      {
        label: "pipeText",
        buttonText: "Pipe text",
        type: "text",
        required: true
      }
    ]
  }
}

export default TOOL_DATA;