import React, { Component } from 'react';
import Toy from "../../components/Toy";
import toyFirebase from "../../util/toyFirebase";
import BasicToolControls from "../../components/tools/BasicToolControls";
import TOOL_DATA from "../../util/toolData";

const uuidv4 = require("uuid/v4");
const createToy = toyFirebase.functions().httpsCallable("createToy");

class ToolContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      toyData: {},
      fields: [],
      description: ""
    };
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params) {
      let toyType = this.props.match.params.toyType;
      let toolData = TOOL_DATA[toyType];
      toolData['toyType'] = toyType;

      this.setState(toolData);
    }
  }

  getToyUrl(toyId) {
    return `/toy/${toyId}`;
  }

  isReady() {
    if (this.state.isCreating) {
      return false;
    }

    // Iterate through all required fields
    let allFieldsPresent = true;
    this.state.fields.forEach(fieldData => {

      let label = fieldData.label;
      if (fieldData.required) {
        if (!this.state.toyData.hasOwnProperty(label)) {
          allFieldsPresent = false;
        }
      }
    })

    return allFieldsPresent;
  }

  getDoneButtonText() {
    if (this.state.isCreating) {
      return "Building...";
    }

    if (this.isReady()) {
      return "Tap to create";
    }

    return this.state.doneFinishText;
  }

  onClickDone = async () => {
    console.log("Creating");
    this.setState({
      isCreating: true
    });

    console.log(this.state.toyType);
    let result = await createToy({toyData: this.state.toyData, type: this.state.toyType});
    const toyId = result.data.toyId;

    this.props.history.push(this.getToyUrl(toyId));
  }

  onToyData = (toyData) => {
    let oldToyData = this.state.toyData;
    Object.assign(oldToyData, toyData);
    this.setState({
      toyData: oldToyData
    });
  }

  // TODO use different setState
  onToyDataUpdate = (label, value) => {
    let toyData = this.state.toyData;
    toyData[label] = value;
    this.setState({
      toyData
    });
  }

  render() {
    return (
      <div className="TwoImageTool">
        <div style={{ marginBottom: "8px" }}>{this.state.description}</div>
        <div className="ToolPreview">
          <Toy preview={true} size={"80%"} toyData={this.state.toyData} type={this.state.toyType} />
        </div>
        <BasicToolControls {...this.state} onToyData={this.onToyData} onToyDataUpdate={this.onToyDataUpdate} />
        <button
            disabled={!this.isReady()}
            type="button"
            className="btnUpload"
            onClick={this.onClickDone}
          >
            {this.getDoneButtonText()}
          </button>
      </div>
    );
  }
}

export default ToolContainer;