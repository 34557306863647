import React, { Component } from "react";
import toyFirebase from "../util/toyFirebase";
import FileUploader from "react-firebase-file-uploader";

class ImageUploadButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      doneUploading: false,
      progress: ""
    }
  }

  handleProgress = progress => this.setState({ progress });

  handleUploadSuccess = (filename) => {
    this.setState({ doneUploading: true });

    if (this.props.onImageUploadDone) {
      this.props.onImageUploadDone(this.props.label);
    }
  };

  getProgress = () => {
    return this.state.progress;
  }

  getTitle() {
    if (this.props.isDone) {
      return "Done";
    }

    if (this.state.doneUploading) {
      return "Processing"
    } else {
      return this.props.title;
    }
  }

  render() {
    return (
      <div style={{ position: "relative" }}>
        <label
          style={{
            backgroundColor: this.props.color,
            color: "white",
            pointer: "cursor",
            display: "block",
            width: "100%",
            height: "50px",
            textAlign: "center",
            lineHeight: "50px"
          }}
        >
          {this.getTitle()}
          <FileUploader
            hidden
            randomizeFilename
            accept="image/*"
            storageRef={toyFirebase.storage().ref("images")}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
            metadata={{
              customMetadata: {
                sessionId: this.props.sessionId,
                label: this.props.label
              }
            }}
          />
        </label>
        <div
          style={{
            position: "absolute",
            width: this.getProgress() + "%",
            height: "100%",
            backgroundColor: "#ffffff88",
            top: "0px",
            left: "0px"
          }}
        />
      </div>
    );
  }
}

export default ImageUploadButton;
