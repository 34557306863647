import React, { Component } from "react";
import Toy from "../components/Toy";
import "../css/ToyViewer.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import TOOL_DATA from "../util/toolData";

export default class ToyViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toyId: ""
    };
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params) {
      let toyId = this.props.match.params.toyId;

      if (!toyId) {
        return;
      }

      this.setState({
        toyId
      });
    }
  }

  onToyData = toyData => {
    this.setState({
      toyData
    });
  };

  getInstructions() {
    if (!this.state.toyData || !this.state.toyData.type) {
      return null;
    }

    const type = this.state.toyData.type;
    const directions = TOOL_DATA[type].directions;

    return (
      <span>
        <b>{type}</b> {directions}
      </span>
    );
  }

  render() {
    return (
      <div>
        <div className="ToyFooter">{this.getInstructions()}</div>
        <Toy
          user="@user"
          toyId={this.state.toyId}
          size={this.props.size}
          onToyData={this.onToyData}
        />

        <div>
          <input className="TxtUrl" readOnly value={window.location.href} />
        </div>

        <CopyToClipboard
          text={window.location.href}
          onCopy={() => this.setState({ copied: true })}
        >
          <button className="BtnCopy">{this.state.copied ? "Copied" : "Copy URL"}</button>
        </CopyToClipboard>
      </div>
    );
  }
}
