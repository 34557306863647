import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom'
import ToolSelect from "./screens/ToolSelect";
import ToyViewer from "./screens/ToyViewer";
import toyFirebase from './util/toyFirebase';
import Toy from "./toys/FlappyToy";
import ToolContainer from './screens/tools/ToolContainer';

const TOOL_PREFIX = "create";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {
    // Prevent context menu from popping up
    // on mobile
    window.oncontextmenu = function(event) {
         event.preventDefault();
         event.stopPropagation();
         return false;
    };

    // Sign in anonymously
    toyFirebase.auth().signInAnonymously().catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
    });

    toyFirebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        var isAnonymous = user.isAnonymous;
        var uid = user.uid;
        
        console.log("Signed in", uid);
      } else {

      }

    });
  }

  render() {
    return (
      <div className="App">
        <h1 style={{textAlign: "center"}}><a href="/">ToyBrush</a></h1>
        <Router>
          <div>
            <Route exact path="/" component={ToolSelect}/>
            <Route exact path={`/${TOOL_PREFIX}/:toyType`} component={ToolContainer}/>
            <Route exact path="/toy/:toyId" component={ToyViewer}/>
            <Route exact path="/test" component={Toy}/>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;