import React, { Component } from "react";
import ImageUploadButton from "../ImageUploadButton";
import toyFirebase from "../../util/toyFirebase";
import "../../css/BasicToolControl.css";
const uuidv4 = require("uuid/v4");

const BUTTON_COLORS = ["#f442b0", "#4b99ff", "#4b99ff"];

class BasicToolControls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionId: ""
    };
  }

  componentDidMount() {
    const sessionId = uuidv4();

    this.setState({
      sessionId: sessionId
    });

    // TODO listen for sessionID temp firestore data
    this.listenForSessionData(sessionId);
  }

  listenForSessionData(sessionId) {
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    this.unsubscribe = toyFirebase
      .firestore()
      .collection("sessions")
      .doc(sessionId)
      .onSnapshot(doc => {
        if (!doc.exists) {
          return;
        }
        let toyData = doc.data();

        if (this.props.onToyData) {
          this.props.onToyData(toyData);
        }

        let labels = Object.keys(toyData);
        let labelState = {};
        labels.forEach(label => {
          labelState[label] = true;
        });

        this.setState(labelState);
      });
  }

  renderControls() {
    if (!this.props.fields) {
      return null;
    }

    return this.props.fields.map((fieldData, idx) => {
      let label = fieldData.label;
      let type = fieldData.type;
      let buttonText = fieldData.buttonText;

      return this.getComponentForType(type, label, buttonText, idx, fieldData);
    });
  }

  updateToyData(label, value) {
    if (this.props.onToyDataUpdate) {
      this.props.onToyDataUpdate(label, value);
    }
  }

  onChange = e => {
    let label = e.target.name;
    let value = e.target.value;

    this.updateToyData(label, value);

    this.setState({
      [label]: value
    });
  };

  getComponentForType(type, label, buttonText, idx, fieldData) {
    switch (type) {
      case "image": {
        return (
          <ImageUploadButton
            key={label}
            title={buttonText}
            sessionId={this.state.sessionId}
            label={label}
            color={BUTTON_COLORS[idx % BUTTON_COLORS.length]}
            isDone={this.state[label] === true}
          />
        );
      }

      case "text": {
        return (
          <input
            key={label}
            placeholder={buttonText}
            type="text"
            name={label}
            onChange={this.onChange}
            value={this.state[label]}
            style={{
              width: "100%",
              height: "50px",
              fontSize: "24px",
              border: "none",
              borderBottom: "1px solid #ccc"
            }}
          />
        );
      }

      case "image_picker": {
        let images = fieldData.images;
        let imgElements = images.map((url, idx) => {

          let border = this.state[label] === url ? "2px solid #f442b0" : "none";

          return (
            <img
              key={label + "_" + idx}
              onClick={() => {
                this.setState({ [label]: url });
                this.updateToyData(label, url);
              }}
              style={{ border: border, borderRadius: "4px" }}
              src={url}
            />
          );
        });

        return (
          <div
            key={label + "_img"}
            style={{
              width: "100%",
              height: "50px",
              fontSize: "24px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              padding: "4px"
            }}
          >
            {buttonText}
            {imgElements}
          </div>
        );
      }
    }
  }

  render() {
    return <div>{this.renderControls()}</div>;
  }
}

export default BasicToolControls;
