import React, { Component } from 'react';
import "../css/BlinkerToy.css";

import Pic1 from "../images/toys/blinker/first.png";
import Pic2 from "../images/toys/blinker/second.png";

class BlinkerToy extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      currentImg: this.getPic1(),
      currentText: this.props.text1
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.pic1Url && this.props.pic1Url) {
      console.log("Change");
      this.setState({
        currentImg: this.props.pic1Url
      });
    }

    if (prevProps.text1 != this.props.text1) {
      this.setState({
        currentText: this.props.text1
      });
    }
  }

  onTouchStart = () => {
    this.setState({
      currentImg: this.getPic2(),
      currentText: this.props.text2
    })
  }

  onTouchEnd = () => {
    this.setState({
      currentImg: this.getPic1(),
      currentText: this.props.text1
    })
  }

  getPic1() {
    if (this.props.pic1Url) {
      return this.props.pic1Url;
    } else if (this.props.isPreview) {
      return Pic1;
    }
  }

  getPic2() {
    if (this.props.pic2Url) {
      return this.props.pic2Url;
    } else if (this.props.isPreview) {
      return Pic2;
    }
  }

  render() {
    return (
      <div className="BlinkerToy" onMouseDown={this.onTouchStart} onMouseUp={this.onTouchEnd} onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd}>
        <img className="BlinkerImg" src={this.state.currentImg} />
        {this.state.currentText && <div className="BlinkerCaption">{this.state.currentText}</div>}
      </div>
    );
  }
}

export default BlinkerToy;