import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import tileData from '../util/toolTileData';

// TODO move to tileData
import ScratchDemo from "../images/gifs/scratch_demo.gif";
import BlinkerDemo from "../images/gifs/blinker_demo.gif";
import DumperDemo from "../images/gifs/dumper_demo.gif";
import FlappyDemo from "../images/gifs/flappy_demo.gif";

const imageMap = {
  scratch: ScratchDemo,
  blinker: BlinkerDemo,
  dumper: DumperDemo,
  flappy: FlappyDemo
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%'
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  footer: {
    marginTop: '12px'
  }
});

function goToTool(type) {
  window.location.assign(`/create/${type}`);
}

function TitlebarGridList(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div>Pick a template 👇👇👇</div>
      <GridList cellHeight={180} className={classes.gridList}>
        {tileData.map(tile => (
          <GridListTile key={tile.img} onClick={() => goToTool(tile.type)}>
            <img src={imageMap[tile.type]} alt={tile.title} />
            <GridListTileBar
              title={tile.title}
              subtitle={<span>{tile.subtitle}</span>}
            />
          </GridListTile>
        ))}
      </GridList>
      <div className={classes.footer}>More coming soon!</div>
    </div>
  );
}

export default withStyles(styles)(TitlebarGridList);